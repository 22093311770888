<template>
  <div class="hold-transition">
    <div class="modal fade"
      id="modal-form-reporte"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar reporte de contratos</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="form-group col-md-12">
                      <label class="col-md-5">Empresa</label>
                      <v-select
                        :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                        v-model="form.empresa"
                        placeholder="Empresas"
                        label="razon_social"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.empresas"
                        @input="selectEmpresa()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label class="col-md-5"
                        >Fecha inicial</label
                      >
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_ini"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label class="col-md-5">Fecha final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_fin"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label class="col-md-5">Valor</label>
                      <input
                        type="num"
                        class="form-control form-control-sm"
                        v-model="form.valor"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label class="col-md-5">Estado</label>
                      <select class="form-control form-control-sm"
                        v-model="form.estado"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estados in listasForms.estado"
                          :key="estados.numeracion"
                          :value="estados.numeracion"
                        >
                          {{ estados.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('cs.contratosNacionales.excelCsContratosNal')"
              >
                <i class="fas fa-file-download"> </i>
                Generar Listado
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
export default {
  name: "CsContratoNacionalExport",
  components: {
    vSelect,
  },
  data() {
    return {
      cargando: false,
      form: {
        empresa: null,
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        valor: null,
        estado: null,
      },
      listasForms: {
        empresas: [],
        bloque: [],
        estado: [],
      },
    };
  },

  methods: {
    getEmpresas() {
      axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [4],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },

    selectEmpresa() {
      this.form.empresa_id = this.form.empresa.id;
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estado = response.data;
      });
    },

    generarListadoExcel() {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/cs/contratosNacionales/reporte",
        data: this.form,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          if (!data.error) {
            this.$swal({
              icon: "success",
              title: data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            location.href = data.url;
          } else {
            this.$swal({
              icon: "error",
              title: data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },

  async mounted() {
    this.$parent.cargando = true;
    this.getEmpresas();
    this.getEstados();
    this.$parent.cargando = false;
  },
};
</script>
